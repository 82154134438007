import type { CreateOrderParams } from "@shopware-pwa/types";
import { useShopwareSession } from "./useShopwareSession";
import {
	getAvailableShippingMethods,
	getAvailablePaymentMethods,
	createOrder as createOrderViaClient
} from "@shopware-pwa/api-client";
import type { CustomerAddress } from "@shopware-pwa/types";

const shippingMethodsAssociations = {
	associations: {
		prices: {}
	}
};

const useShopwareCheckoutImplementation = () => {
	const apiInstance = useShopwareApi();
	const { sessionContext, selectedPaymentMethod, selectedShippingMethod, setShippingMethod, setPaymentMethod } =
		useShopwareSession();
	const { onAddPaymentInfo } = useGtagEvents();

	const { state: shippingMethods } = useData(async () => {
		const response = await getAvailableShippingMethods(apiInstance, shippingMethodsAssociations);
		return response?.elements.sort((a, b) => (a.position ?? 0) - (b.position ?? 0)) || [];
	}, []);

	const { state: paymentMethods, execute: refreshPaymentMethods } = useData(async () => {
		const response = await getAvailablePaymentMethods(apiInstance, {
			onlyAvailable: true
		});

		const paymentMethods = response?.elements.sort((a, b) => a.name!.localeCompare(b.name!));

		if (paymentMethods.length === 0) {
			useBugsnag().notify(new Error("No payment methods available after fetching"));
			console.error("No payment methods available!");
		}

		return paymentMethods || [];
	}, []);

	const createOrder = async (params: CreateOrderParams) => {
		const order = await createOrderViaClient(params, apiInstance);
		return order;
	};
	const shippingAddress = computed<CustomerAddress | undefined>(
		() => sessionContext.value?.shippingLocation?.address
	);
	const billingAddress = computed(() => sessionContext.value?.customer?.activeBillingAddress);

	watch([selectedShippingMethod], async () => {
		await refreshPaymentMethods();
		if (
			paymentMethods.value.map((p) => p.id).includes(selectedPaymentMethod.value?.id || "") ||
			!paymentMethods.value.length
		) {
			return;
		}
		await setPaymentMethod(paymentMethods.value[0].id);
		onAddPaymentInfo();
	});

	return {
		refreshPaymentMethods,
		paymentMethods,
		shippingMethods,
		createOrder,
		shippingAddress,
		billingAddress,
		selectedShippingMethod,
		setShippingMethod,
		selectedPaymentMethod,
		setPaymentMethod
	};
};

export const useShopwareCheckout = createSharedComposable(useShopwareCheckoutImplementation);
